import React, {useState, useEffect} from 'react'
import Layout from './layout'
import {navigate} from 'gatsby'
import {isLoggedIn} from '../services/auth'

import { useSelector, useDispatch } from "react-redux"


const LayoutSecure = ({ children }) => {

  const userInfo = useSelector(state => state.userInfo)

  const dispatch = useDispatch()
  // console.log('---> state data: ', userInfo)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // check is reset-password
    const urlParams = new URLSearchParams(window.location.search);
    const _email = urlParams.has('email') ? urlParams.get('email') : ''
    const _token = urlParams.has('token') ? urlParams.get('token') : ''
    const _action = urlParams.has('action') ? urlParams.get('action') : null
    if(_action === 'reset-password'){
        navigate(`/auth/change-password/?token=${_token}&email=${_email}`)
    }
    else{
      // check logged in
      if(!userInfo){
        setIsLoading(true)
        isLoggedIn().then(login => {
          if(login){
            dispatch({ type: 'SET_USER', payload: login })
            setIsLoading(false)
          }
          else{
            dispatch({ type: 'SET_USER', payload: null })
            navigate("/auth/login")
          }
        })
      }
    }
  }, [])


  // return (
  //   <Layout>
  //     {children}
  //   </Layout>
  // )

  return (
    <Layout>
      {userInfo && children}
      {isLoading && <Loader />}
      {/* {userInfo ? children : <Loader />} */}
    </Layout>
  )
}
export default LayoutSecure


const Loader = () => (
  <div className="d-flex justify-content-center mt-5 mb-5">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
)